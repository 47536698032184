const styles = {
  container: (provided) => ({
    ...provided,
    fontSize: "0.9rem",
  }),
  control: (provided) => ({
    ...provided,
    height: 52,
    borderRadius: 20,
    margin: "8px 0",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-evenly",
  }),
  option: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "flex-start",
  }),
};

export default styles;

import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Button, Icon } from "../../components"
import useApi from "../../hooks/useApi"
import useToast from "../../hooks/useToast"
import url from "../../const/url"
import { useAppContext } from "../../context"
import {
  Title,
  Subtitle,
  CategoriesContainer,
  CategoryContainer,
  CategoryImg,
  Heart,
  ImageContainer,
  IconContainer,
} from "./styles"

function CategoryView() {
  const [artists, setArtists] = useState([])
  const [usedCredits, setUseCredits] = useState(0)
  const { user, getCategories } = useAppContext()
  const { fetcher } = useApi()
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const handleBack = () => {
    getCategories()
    navigate("/main")
  }

  const getArtist = () => {
    fetcher('GET',`/user/${user.id}/category/${params.id}/artist`)
      .then((data) => {
        const {artists, voted} = data.response.data.content
        
        setUseCredits(voted.map(v => Number(v.count)).reduce((a, b) => a + b, 0))
        setArtists(artists.map(a => {
          a.isVoted = !!voted.find(v => v.id === a.id)

          return a
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const confirmVote = async (item) => {
    const response = window.confirm(
      `¿Estas seguro de votar por el artista ${item.name}?`
    )

    if (!response) return


    const {response:data, error} = await fetcher(
      "POST",
      "/votes",
      {
        user_id: user.id,
        category_id: Number(params.id),
        artist_id: Number(item.id),
      }
    )

    console.log(data)

    if(data) {
      toast.success(data.message ?? "Gracias por apoyar a tu artistas favorito")
      return 
    }

    if (error) {
      toast.error(error?.response?.data?.message ?? "Ocurrio un error al votar, intente mas tarde")
    }
      
  }

  useEffect(() => {
    if (!user || !params?.id) return

    getArtist()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, params])

  return (
    <>
      <Title>Categoría {params.name}</Title>
      <Subtitle>Elige a tu favorito</Subtitle>
      {/* <Subtitle>Créditos usados: {usedCredits}</Subtitle> */}
      <CategoriesContainer>
        {artists.map((item) => (
          <Category key={item.id} {...item} onClick={confirmVote} />
        ))}
      </CategoriesContainer>
      <Button variant="primary" onClick={handleBack}>
        Volver a categorias
      </Button>
    </>
  )
}

const Category = ({ id, image, name, isVoted, onClick }) => (
  <CategoryContainer onClick={() => onClick({ id, name })}>
    <ImageContainer>
      <CategoryImg src={image} alt={name} loading="lazy" />
      {isVoted && (
        <IconContainer style={{ position: 'absolute' }}><Icon name="favorite" size="52" /></IconContainer>
      )}
    </ImageContainer>
    
    <p className="text">{name}</p>
  </CategoryContainer>
)

export default CategoryView

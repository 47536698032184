import styled from "@emotion/styled/macro";
import { mqMax, mqMin } from "../../const/mixins";

export const Title = styled.h3`
  color: ${(props) => props.theme.primary[500]};
  text-transform: capitalize;
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.grayscale.white};
  margin: 8px 0;
`;

export const CategoryContainer = styled.div`
  cursor: pointer;
  background: linear-gradient(10deg, rgba(233,178,102,1) 0%, rgba(244,212,155,1) 100%);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  & > .text {
    text-transform: capitalize;
    font-size: 0.8rem;
    text-align: center;
    padding: 4px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props) => mqMax(props.theme.breakpoints.xs)} {
    & > .text {
      font-size: 0.65rem;
      margin: 0;
      padding: 4px;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`

export const IconContainer = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.primary[500]};
`

export const Heart = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid white;
  margin-top: 8px;
  margin-left: 8px;

  background-color: ${(props) =>
    props.isSelected ? props.theme.primary[500] : "unset"};
`;

export const CategoryImg = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const CategoriesContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;

  ${(props) => mqMax(props.theme.breakpoints.xs)} {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(220px, auto);

    ${CategoryContainer} {
      width: 125px;
      height: 200px;
    }
  }

  ${(props) => mqMin(props.theme.breakpoints.xs)} {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(260px, auto);
    ${CategoryContainer} {
      width: 125px;
      height: 230px;
    }
  }

  ${(props) => mqMin(props.theme.breakpoints.sm)} {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(260px, auto);
    ${CategoryContainer} {
      width: 125px;
      height: 245px;
    }
  }

  ${(props) => mqMin(props.theme.breakpoints.md)} {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(260px, auto);
    ${CategoryContainer} {
      width: 175px;
      height: 245px;
    }
  }
`;

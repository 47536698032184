import { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { ViewContainer, FormContainer, Logo, Title, Subtitle, LoginContainer, DisclaimerText, CommerceLogo } from "./styles"
import { Input, CountryPicker, Button } from "../../components"
import { useAppContext } from "../../context"
import { buildPayURL } from "../../utils/payUrl"
import useToast from "../../hooks/useToast"
import useApi from "../../hooks/useApi"
import logo from "../../assets/img/icono.png"
import commerceLogo from "../../assets/img/wompi.png"

function SignUpView() {
  const [phone, setPhone] = useState("")
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [countryCode, setCountryCode] = useState("CO")
  const [callingCode, setCallingCode] = useState("+57")
  const [price, setPrice] = useState(0)

  const { setUser, setPrice: setPriceApp } = useAppContext()
  const toast = useToast()
  const { fetcher, catcher } = useApi()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  const handlerPhone = ({ target }) => {
    setPhone(target.value)
  }

  const handlerName = ({ target }) => {
    setName(target.value)
  }

  const getVotePrice = async () => {
    const response = await fetcher('GET', `/payments/value`);
    catcher(response,
      (data) => {
        setPrice(data.content)
        setPriceApp(data.content)
      },
      (error) => {
        console.log('Vali verga', error);
        alert('Parece que hubo un error')
      }
    );
  }

  const onSubmitLogin = async () => {
    if (name === "" || phone === "") {
      toast.error("Digite todos los campos.")
      return
    }

    if (phone.length < 5) {
      toast.error("Digite un número válido.")
      return
    }

    setIsLoading(true)

    const { response } = await fetcher('POST','/auth/login', {
      cellphone: phone,
      first_name: name,
      country_code: callingCode,
      country:countryCode.toUpperCase()
    })

    if (response && response.data) {
      const { user } = response.data.content

      if (user && user.credits > 0) {
        setUser({ ...user })
        navigate("/main")
        return
      } 

      /* if (ref) {
        const { ref: payReference, isOld } = ref
        const urlPay = buildPayURL(payReference, price)
        
        if (isOld) {
          toast.warn("Tienes una transferencia en curso, vuelve a intentarlo en unos momentos")
          return
        }

        setUser({ ...user })
        window.location.replace(urlPay.href)

        return
      } */
    } else {
      toast.error('Parece que hubo un error de conexión intente mas tarde')
    }
  }

  useEffect(() => {
    getVotePrice()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = searchParams.get('id')
    if (!id) return

    navigate(`/pay-validator?id=${id}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <ViewContainer>
      <FormContainer>
        <Logo src={logo} alt="Upar Awards Logo" />
        <Title>Te estábamos esperando!</Title>
        <Subtitle>Para que elijas a tus ártistas favoritos</Subtitle>
        <LoginContainer>
          <CountryPicker
            disabled={isLoading}
            value={countryCode}
            setValue={({ dial_code, code }) => {
              setCountryCode(code)
              setCallingCode(dial_code)
            }}
          />
          <Input
            disabled={isLoading}
            block
            type="number"
            placeholder="Número de Teléfono"
            value={phone}
            onChange={handlerPhone}
          />
          <Input
            disabled={isLoading}
            block
            type="text"
            placeholder="Nombre Completo"
            value={name}
            onChange={handlerName}
          />
          <Button
            block
            disabled={isLoading}
            variant="success"
            onClick={onSubmitLogin}
          >
            Completar Registro y Votar
          </Button>
          {/* <CommerceLogo src={commerceLogo} alt="wompi logo" />
          <DisclaimerText>Serás redirigido a nuestor sistema de pagos on-line,</DisclaimerText>
          <DisclaimerText>completa todos los pasos y vuelve a la aapp para votar</DisclaimerText> */}
        </LoginContainer>
      </FormContainer>
    </ViewContainer>
  )
}

export default SignUpView
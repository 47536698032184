import Select, { components } from "react-select";
import styles from "./styles";
import countries from "./countries";

function CountryPicker({ value = "co", setValue, disabled = false }) {
  const Component = ({ code, dial_code, name }) => (
    <>
      <img src={`https://flagcdn.com/${code}.svg`} width="24" alt={name}></img>
      <div>
        <span style={{ padding: "0px 8px" }}>({dial_code})</span>{" "}
        <span>{name}</span>
      </div>
    </>
  );

  const Option = ({ data, ...rest }) => (
    <components.Option {...rest}>
      <Component {...data} />
    </components.Option>
  );

  const SingleValue = ({ data, ...rest }) => (
    <components.SingleValue {...rest}>
      <Component {...data} />
    </components.SingleValue>
  );

  return (
    <Select
      isDisabled={disabled}
      styles={styles}
      defaultValue={countries.find((item) => item.code === value.toLowerCase())}
      getOptionLabel={(option) => `${option.name}`}
      getOptionValue={(option) => `${option.code}`}
      options={countries}
      components={{ Option, SingleValue }}
      onChange={setValue}
    />
  );
}

export default CountryPicker;

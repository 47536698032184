import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../context";
import { CustomLink } from "../../components";
import {
  LayoutContainer,
  HeaderContainer,
  HeaderContent,
  Logo,
  NavBlock,
  NavList,
  NavItemWrap,
  NavContent,
  MainContent,
} from "./styles";
import logo from "../../assets/img/icono.png";

const routes = [{ path: "/main/categories", title: "Categorias", icon: "" }];

function MainLayout() {
  const { user } = useAppContext();
  const location = useLocation();
  return user ? (
    <LayoutContainer>
      <HeaderContainer>
        <HeaderContent>
          <Logo src={logo} alt="Upar Awards Logo" />
          <NavBlock>
            <NavList>
              {routes.map((item, key) => (
                <NavItemWrap
                  key={key}
                  active={location.pathname.includes(item.path)}
                >
                  <CustomLink to={item.path}>
                    <NavContent>
                      <span>{item.title}</span>
                    </NavContent>
                  </CustomLink>
                </NavItemWrap>
              ))}
              <NavItemWrap>
                <a
                  target="_blank"
                  href="http://uparawards.com.co/index.php/nosotros/"
                  rel="noreferrer"
                >
                  <NavContent>
                    <span>Nosotros</span>
                  </NavContent>
                </a>
              </NavItemWrap>
              <NavItemWrap>
                <a
                  target="_blank"
                  href="http://uparawards.com.co/index.php/noticias/"
                  rel="noreferrer"
                >
                  <NavContent>
                    <span>Noticias</span>
                  </NavContent>
                </a>
              </NavItemWrap>
            </NavList>
          </NavBlock>
        </HeaderContent>
      </HeaderContainer>
      <MainContent>
        <Outlet />
      </MainContent>
    </LayoutContainer>
  ) : (
    <Navigate to="/" />
  );
}

export default MainLayout;

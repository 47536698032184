import axios from "axios"
import baseUrl from "../const/url"

function useApi() {
  const fetcher = async (type, url, data, headers) => {
    const base_url = baseUrl.url

    /* if(!headers){
      headers = {
        'Content-type': 'Application/json',
      Accept: 'Application/json',
      }
    } */

    return axios({
      method: type,
      url: `${base_url}${url}`,
      data: data ? data : undefined,
      headers,
    })
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
  }

  const catcher = (responseObject, successCallback, errorCallback) => {
    const { response, error } = responseObject
    if ((response && response.status === 200) || response.status === 201) {
      if (successCallback) {
        successCallback(response?.data)
      }
    } else if (error) {
      if (errorCallback) {
        errorCallback(error)
      }
    }
  }

  return { fetcher, catcher }
}

export default useApi

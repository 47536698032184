import { Link } from "react-router-dom";

function CustomLinkComponent({ children, to, ...props }) {
  return (
    <Link
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
}

export default CustomLinkComponent;

const baseUrl = {
    url: 'https://s4hibmcj62.execute-api.us-east-1.amazonaws.com/dev',
    base: 'https://s4hibmcj62.execute-api.us-east-1.amazonaws.com/dev'
}

export const redirectUrl = "https://votacion.uparawards.com.co/"
export const payURL = "https://checkout.wompi.co/p/"
export const publicKey = "pub_prod_EezZYm4FkjBBWa2LRpKlHH7jxybETazp"
//export const publicKey = "pub_test_TIgj8EcBgae0J1wt7jAWNwuLnnIlHKE1"


export default baseUrl;

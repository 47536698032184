import styled from '@emotion/styled';

const type = {
  filled: '',
  rounded: ' Round',
  outlined: ' Outlined',
  sharp: ' Sharp',
  twoTone: ' Two Tone'
}

export const Em = styled.em`
  font-family: 'Material Icons${props => type[props.type]}';
  font-weight: normal;
  font-style: normal;
  font-size: ${(props) => props.size ?? 24}px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
`;

function IconComponent({ name, type = 'rounded', size = undefined }) {
  return <Em size={size} type={type}>{name}</Em>;
}

export default IconComponent;

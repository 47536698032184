import styled from "@emotion/styled";
import { mqMax, mqMin } from "../../const/mixins";

export const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.grayscale.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  ${(props) => mqMax(props.theme.breakpoints.xs)} {
    width: 80%;
  }

  ${(props) => mqMin(props.theme.breakpoints.xs)} {
    width: 350px;
  }
`;

export const Logo = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const Title = styled.h3`
  margin: 1rem 0 0 0;
  text-align: center;
  color: ${(props) => props.theme.primary[500]};
`;

export const Subtitle = styled.p`
  text-align: center;
  color: ${(props) => props.theme.grayscale.white};
  margin: 8px 0;
`;

export const LoginContainer = styled.div`
  background: linear-gradient(10deg, rgba(233,178,102,1) 0%, rgba(244,212,155,1) 100%);
  margin: 24px 0;
  padding: 24px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
`

export const DisclaimerText = styled.p`
  color: #020101;
  text-align: center;
  font-size: 0.6rem;
  margin-bottom: 0;
`

export const CommerceLogo = styled.img`
  width: 80px;
  object-fit: cover;
  align-self: center;
`;

import styled from "@emotion/styled";

export const StyledInput = styled.input`
  height: 52px;
  width: ${(props) => (props.block ? "100%" : "unset")};
  margin: 8px 0;
  border: none;
  border-radius: 20px;
  font-weight: bold;

  &[type="text"],
  &[type="number"],
  &[type="password"] {
    padding: 4px 15px;
  }
`;

export const StyledLabel = styled.label`
  font-size: 0.85rem;
  margin-top: 8px;
  color: ${(props) => props.theme.grayscale.white};
`;

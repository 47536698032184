const countries = [
  {
    name: "Afganistán",
    dial_code: "+93",
    code: "af",
  },
  {
    name: "Åland",
    dial_code: "+358",
    code: "ax",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "al",
  },
  {
    name: "Argelia",
    dial_code: "+213",
    code: "dz",
  },
  {
    name: "Samoa Americana",
    dial_code: "+1684",
    code: "as",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "ad",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "ao",
  },
  {
    name: "Anguila",
    dial_code: "+1264",
    code: "ai",
  },
  {
    name: "Antártida",
    dial_code: "+672",
    code: "aq",
  },
  {
    name: "Antigua y Barbuda",
    dial_code: "+1268",
    code: "ag",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "ar",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "am",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "aw",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "au",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "at",
  },
  {
    name: "Azerbaiyán",
    dial_code: "+994",
    code: "az",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "bs",
  },
  {
    name: "Baréin",
    dial_code: "+973",
    code: "bh",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "bd",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "bb",
  },
  {
    name: "Bielorrusia",
    dial_code: "+375",
    code: "by",
  },
  {
    name: "Bélgica",
    dial_code: "+32",
    code: "be",
  },
  {
    name: "Belice",
    dial_code: "+501",
    code: "bz",
  },
  {
    name: "Benín",
    dial_code: "+229",
    code: "bj",
  },
  {
    name: "Bermudas",
    dial_code: "+1441",
    code: "bm",
  },
  {
    name: "Bután",
    dial_code: "+975",
    code: "bt",
  },
  {
    name: "Bolivia",
    dial_code: "+591",
    code: "bo",
  },
  {
    name: "Bosnia y Herzegovina",
    dial_code: "+387",
    code: "ba",
  },
  {
    name: "Botsuana",
    dial_code: "+267",
    code: "bw",
  },
  {
    name: "Brasil",
    dial_code: "+55",
    code: "br",
  },
  {
    name: "Territorio Británico del Océano Índico",
    dial_code: "+246",
    code: "io",
  },
  {
    name: "Brunéi",
    dial_code: "+673",
    code: "bn",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "bg",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "bf",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "bi",
  },
  {
    name: "Camboya",
    dial_code: "+855",
    code: "kh",
  },
  {
    name: "Camerún",
    dial_code: "+237",
    code: "cm",
  },
  {
    name: "Canadá",
    dial_code: "+1",
    code: "ca",
  },
  {
    name: "Cabo Verde",
    dial_code: "+238",
    code: "cv",
  },
  {
    name: "Islas Caimán",
    dial_code: "+ 345",
    code: "ky",
  },
  {
    name: "República Centroafricana",
    dial_code: "+236",
    code: "cf",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "td",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "cl",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "cn",
  },
  {
    name: "Isla de Navidad",
    dial_code: "+61",
    code: "cx",
  },
  {
    name: "Islas Cocos",
    dial_code: "+61",
    code: "cc",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "co",
  },
  {
    name: "Comoras",
    dial_code: "+269",
    code: "km",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "cg",
  },
  {
    name: "Congo (Rep. Dem.)",
    dial_code: "+243",
    code: "cd",
  },
  {
    name: "Islas Cook",
    dial_code: "+682",
    code: "ck",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "cr",
  },
  {
    name: "Costa de Marfil",
    dial_code: "+225",
    code: "ci",
  },
  {
    name: "Croacia",
    dial_code: "+385",
    code: "hr",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "cu",
  },
  {
    name: "Chipre",
    dial_code: "+357",
    code: "cy",
  },
  {
    name: "República Checa",
    dial_code: "+420",
    code: "cz",
  },
  {
    name: "Dinamarca",
    dial_code: "+45",
    code: "dk",
  },
  {
    name: "Yibuti",
    dial_code: "+253",
    code: "dj",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "dm",
  },
  {
    name: "República Dominicana",
    dial_code: "+1849",
    code: "do",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "ec",
  },
  {
    name: "Egipto",
    dial_code: "+20",
    code: "eg",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "sv",
  },
  {
    name: "Guinea Ecuatorial",
    dial_code: "+240",
    code: "gq",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "er",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "ee",
  },
  {
    name: "Etiopía",
    dial_code: "+251",
    code: "et",
  },
  {
    name: "Islas Malvinas",
    dial_code: "+500",
    code: "fk",
  },
  {
    name: "Islas Feroe",
    dial_code: "+298",
    code: "fo",
  },
  {
    name: "Fiyi",
    dial_code: "+679",
    code: "fj",
  },
  {
    name: "Finlandia",
    dial_code: "+358",
    code: "fi",
  },
  {
    name: "Francia",
    dial_code: "+33",
    code: "fr",
  },
  {
    name: "Guayana Francesa",
    dial_code: "+594",
    code: "gf",
  },
  {
    name: "Polinesia Francesa",
    dial_code: "+689",
    code: "pf",
  },
  {
    name: "Gabón",
    dial_code: "+241",
    code: "ga",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "gm",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "ge",
  },
  {
    name: "Alemania",
    dial_code: "+49",
    code: "de",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "gh",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "gi",
  },
  {
    name: "Grecia",
    dial_code: "+30",
    code: "gr",
  },
  {
    name: "Groenlandia",
    dial_code: "+299",
    code: "gl",
  },
  {
    name: "Granada",
    dial_code: "+1473",
    code: "gd",
  },
  {
    name: "Guadalupe",
    dial_code: "+590",
    code: "gp",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "gu",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "gt",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "gg",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "gn",
  },
  {
    name: "Guinea-Bisáu",
    dial_code: "+245",
    code: "gw",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "gy",
  },
  {
    name: "Haití",
    dial_code: "+509",
    code: "ht",
  },
  {
    name: "Ciudad del Vaticano",
    dial_code: "+379",
    code: "va",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "hn",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "hk",
  },
  {
    name: "Hungría",
    dial_code: "+36",
    code: "hu",
  },
  {
    name: "Islandia",
    dial_code: "+354",
    code: "is",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "in",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "id",
  },
  {
    name: "Irán",
    dial_code: "+98",
    code: "ir",
  },
  {
    name: "Irak",
    dial_code: "+964",
    code: "iq",
  },
  {
    name: "Irlanda",
    dial_code: "+353",
    code: "ie",
  },
  {
    name: "Isla de Man",
    dial_code: "+44",
    code: "im",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "il",
  },
  {
    name: "Italia",
    dial_code: "+39",
    code: "it",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "jm",
  },
  {
    name: "Japón",
    dial_code: "+81",
    code: "jp",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "je",
  },
  {
    name: "Jordania",
    dial_code: "+962",
    code: "jo",
  },
  {
    name: "Kazajistán",
    dial_code: "+77",
    code: "kz",
  },
  {
    name: "Kenia",
    dial_code: "+254",
    code: "ke",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "ki",
  },
  {
    name: "Corea del Norte",
    dial_code: "+850",
    code: "kp",
  },
  {
    name: "Corea del Sur",
    dial_code: "+82",
    code: "kr",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "kw",
  },
  {
    name: "Kirguistán",
    dial_code: "+996",
    code: "kg",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "la",
  },
  {
    name: "Letonia",
    dial_code: "+371",
    code: "lv",
  },
  {
    name: "Líbano",
    dial_code: "+961",
    code: "lb",
  },
  {
    name: "Lesoto",
    dial_code: "+266",
    code: "ls",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "lr",
  },
  {
    name: "Libia",
    dial_code: "+218",
    code: "ly",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "li",
  },
  {
    name: "Lituania",
    dial_code: "+370",
    code: "lt",
  },
  {
    name: "Luxemburgo",
    dial_code: "+352",
    code: "lu",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "mo",
  },
  {
    name: "Macedonia del Norte",
    dial_code: "+389",
    code: "mk",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "mg",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "mw",
  },
  {
    name: "Malasia",
    dial_code: "+60",
    code: "my",
  },
  {
    name: "Maldivas",
    dial_code: "+960",
    code: "mv",
  },
  {
    name: "Malí",
    dial_code: "+223",
    code: "ml",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "mt",
  },
  {
    name: "Islas Marshall",
    dial_code: "+692",
    code: "mh",
  },
  {
    name: "Martinica",
    dial_code: "+596",
    code: "mq",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "mr",
  },
  {
    name: "Mauricio",
    dial_code: "+230",
    code: "mu",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "yt",
  },
  {
    name: "México",
    dial_code: "+52",
    code: "mx",
  },
  {
    name: "Micronesia",
    dial_code: "+691",
    code: "fm",
  },
  {
    name: "Moldavia",
    dial_code: "+373",
    code: "md",
  },
  {
    name: "Mónaco",
    dial_code: "+377",
    code: "mc",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "mn",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "me",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "ms",
  },
  {
    name: "Marruecos",
    dial_code: "+212",
    code: "ma",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "mz",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "mm",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "na",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "nr",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "np",
  },
  {
    name: "Países Bajos",
    dial_code: "+31",
    code: "nl",
  },
  {
    name: "Nueva Caledonia",
    dial_code: "+687",
    code: "nc",
  },
  {
    name: "Nueva Zelanda",
    dial_code: "+64",
    code: "nz",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "ni",
  },
  {
    name: "Níger",
    dial_code: "+227",
    code: "ne",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "ng",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "nu",
  },
  {
    name: "Isla Norfolk",
    dial_code: "+672",
    code: "nf",
  },
  {
    name: "Islas Marianas del Norte",
    dial_code: "+1670",
    code: "mp",
  },
  {
    name: "Noruega",
    dial_code: "+47",
    code: "no",
  },
  {
    name: "Omán",
    dial_code: "+968",
    code: "om",
  },
  {
    name: "Pakistán",
    dial_code: "+92",
    code: "pk",
  },
  {
    name: "Palaos",
    dial_code: "+680",
    code: "pw",
  },
  {
    name: "Palestina",
    dial_code: "+970",
    code: "ps",
  },
  {
    name: "Panamá",
    dial_code: "+507",
    code: "pa",
  },
  {
    name: "Papúa Nueva Guinea",
    dial_code: "+675",
    code: "pg",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "py",
  },
  {
    name: "Perú",
    dial_code: "+51",
    code: "pe",
  },
  {
    name: "Filipinas",
    dial_code: "+63",
    code: "ph",
  },
  {
    name: "Islas Pitcairn",
    dial_code: "+872",
    code: "pn",
  },
  {
    name: "Polonia",
    dial_code: "+48",
    code: "pl",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "pt",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "pr",
  },
  {
    name: "Catar",
    dial_code: "+974",
    code: "qa",
  },
  {
    name: "Rumania",
    dial_code: "+40",
    code: "ro",
  },
  {
    name: "Rusia",
    dial_code: "+7",
    code: "ru",
  },
  {
    name: "Ruanda",
    dial_code: "+250",
    code: "rw",
  },
  {
    name: "Reunión",
    dial_code: "+262",
    code: "re",
  },
  {
    name: "San Bartolomé",
    dial_code: "+590",
    code: "bl",
  },
  {
    name: "Santa Elena, Ascensión y Tristán de Acuña",
    dial_code: "+290",
    code: "sh",
  },
  {
    name: "San Cristóbal y Nieves",
    dial_code: "+1869",
    code: "kn",
  },
  {
    name: "Santa Lucía",
    dial_code: "+1758",
    code: "lc",
  },
  {
    name: "San Martín (Francia)",
    dial_code: "+590",
    code: "mf",
  },
  {
    name: "San Pedro y Miquelón",
    dial_code: "+508",
    code: "pm",
  },
  {
    name: "San Vicente y las Granadinas",
    dial_code: "+1784",
    code: "vc",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "ws",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "sm",
  },
  {
    name: "Santo Tomé y Príncipe",
    dial_code: "+239",
    code: "st",
  },
  {
    name: "Arabia Saudita",
    dial_code: "+966",
    code: "sa",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "sn",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "rs",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "sc",
  },
  {
    name: "Sierra Leona",
    dial_code: "+232",
    code: "sl",
  },
  {
    name: "Singapur",
    dial_code: "+65",
    code: "sg",
  },
  {
    name: "Eslovaquia",
    dial_code: "+421",
    code: "sk",
  },
  {
    name: "Eslovenia",
    dial_code: "+386",
    code: "si",
  },
  {
    name: "Islas Salomón",
    dial_code: "+677",
    code: "sb",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "so",
  },
  {
    name: "Sudáfrica",
    dial_code: "+27",
    code: "za",
  },
  {
    name: "Sudán del Sur",
    dial_code: "+211",
    code: "ss",
  },
  {
    name: "Islas Georgias del Sur y Sándwich del Sur",
    dial_code: "+500",
    code: "gs",
  },
  {
    name: "España",
    dial_code: "+34",
    code: "es",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "lk",
  },
  {
    name: "Sudán",
    dial_code: "+249",
    code: "sd",
  },
  {
    name: "Surinam",
    dial_code: "+597",
    code: "sr",
  },
  {
    name: "Svalbard y Jan Mayen",
    dial_code: "+47",
    code: "sj",
  },
  {
    name: "Suazilandia",
    dial_code: "+268",
    code: "sz",
  },
  {
    name: "Suecia",
    dial_code: "+46",
    code: "se",
  },
  {
    name: "Suiza",
    dial_code: "+41",
    code: "ch",
  },
  {
    name: "Siria",
    dial_code: "+963",
    code: "sy",
  },
  {
    name: "Taiwán",
    dial_code: "+886",
    code: "tw",
  },
  {
    name: "Tayikistán",
    dial_code: "+992",
    code: "tj",
  },
  {
    name: "Tanzania",
    dial_code: "+255",
    code: "tz",
  },
  {
    name: "Tailandia",
    dial_code: "+66",
    code: "th",
  },
  {
    name: "Timor Oriental",
    dial_code: "+670",
    code: "tl",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "tg",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "tk",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "to",
  },
  {
    name: "Trinidad y Tobago",
    dial_code: "+1868",
    code: "tt",
  },
  {
    name: "Túnez",
    dial_code: "+216",
    code: "tn",
  },
  {
    name: "Turquía",
    dial_code: "+90",
    code: "tr",
  },
  {
    name: "Turkmenistán",
    dial_code: "+993",
    code: "tm",
  },
  {
    name: "Islas Turcas y Caicos",
    dial_code: "+1649",
    code: "tc",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "tv",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "ug",
  },
  {
    name: "Ucrania",
    dial_code: "+380",
    code: "ua",
  },
  {
    name: "Emiratos Árabes Unidos",
    dial_code: "+971",
    code: "ae",
  },
  {
    name: "Reino Unido",
    dial_code: "+44",
    code: "gb",
  },
  {
    name: "Estados Unidos",
    dial_code: "+1",
    code: "us",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "uy",
  },
  {
    name: "Uzbekistán",
    dial_code: "+998",
    code: "uz",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "vu",
  },
  {
    name: "Venezuela",
    dial_code: "+58",
    code: "ve",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "vn",
  },
  {
    name: "Islas Vírgenes Británicas",
    dial_code: "+1284",
    code: "vg",
  },
  {
    name: "Islas Vírgenes de los Estados Unidos",
    dial_code: "+1340",
    code: "vi",
  },
  {
    name: "Wallis y Futuna",
    dial_code: "+681",
    code: "wf",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "ye",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "zm",
  },
  {
    name: "Zimbabue",
    dial_code: "+263",
    code: "zw",
  },
];

export default countries;

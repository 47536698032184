import { useEffect } from "react";
import { toast } from "react-toastify";

function useToast(options) {
  useEffect(() => {
    const toastInit = (options) => {
      return toast.configure({
        autoClose: 2500,
        draggable: false,
        position: toast.POSITION.TOP_RIGHT,
        limit: 1,
        ...options,
      });
    };

    toastInit(options || {});
  }, [options]);

  return toast;
}

export default useToast;

import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import { ThemeProvider, Global, css } from "@emotion/react";
import { AppProvider } from "./context";
import {
  SignUpView,
  CategoriesListView,
  CategoryView,
  SystemView,
  ReportView,
  PayValidator
} from "./views";
import MainLayout from "./layouts/MainLayout";
import theme from "./theme";
import "react-toastify/dist/ReactToastify.css";

function AppChild() {
  const elements = useRoutes(routes);
  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
            @font-face {
              font-family: "Gordita";
              font-style: normal;
              font-weight: normal;
              src: local(""),
                url("/fonts/gordita_medium.ttf") format("truetype");
            }

            @font-face {
              font-family: "Gordita";
              font-style: normal;
              font-weight: bold;
              src: local(""), url("/fonts/gordita_bold.ttf") format("truetype");
            }

            * {
              box-sizing: border-box;
              font-family: Gordita;
            }
          `}
        />
        {elements}
      </ThemeProvider>
    </AppProvider>
  );
}

const App = () => (
  <BrowserRouter>
    <AppChild />
  </BrowserRouter>
);

export default App;

const routes = [
  { path: "/", element: <SignUpView /> },
  { path: "/system", element: <SystemView /> },
  { path: "/rp", element: <ReportView /> },
  { path: "/pay-validator", element: <PayValidator /> },
  {
    path: "/main",
    element: <MainLayout />,
    children: [
      { index: true, element: <Navigate to="/main/categories" /> },
      { path: "/main/categories", element: <CategoriesListView /> },,
      { path: "/main/categories/:id/:name", element: <CategoryView /> },
    ],
  },
  { path: "*", element: <Navigate to="/" /> },
];

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { ViewContainer, FormContainer, Logo, Title, Subtitle } from "./styles";
import { Input, CountryPicker, Button } from "../../components";
import useToast from "../../hooks/useToast";
import logo from "../../assets/img/icono.png";
import check from "../../assets/img/checked.png";
import useApi from "../../hooks/useApi";
import Select, { components } from "react-select";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function ReportView() {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
  );

  const [category, setCategory] = useState("");
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [sum, setSum] = useState(null);
  const [sum2, setSum2] = useState(null);
  const [confirmation, setConfirm] = useState(true);
  const [password,setPassword] = useState(null);

  const { fetcher } = useApi();

  const [isLoading, setIsLoading] = useState(false);

  const categories = [
    {
      value: 1,
      label: "Mejor agrupación internacional vallenata del año"
    },
    {
      value: 2,
      label: "Mejor agrupación de música tradicional vallenata del año"
    },
    {
      value: 3,
      label: "Mejor cantante femenina vallenata del año"
    },
    {
      value: 4,
      label: "Mejor agrupación profesional vallenata del año"
    },
    {
      value: 5,
      label: "Mejor agrupación juvenil vallenata del año"
    },
    {
      value: 6,
      label: "Mejor agrupación show en vivo vallenato del año"
    },
    {
      value: 7,
      label: "Mejor cantante vallenato romántico del año"
    },
    {
      value: 8,
      label: "Mejor cantautor vallenato del año"
    },
    {
      value: 9,
      label: "Mejor mánager vallenato del año"
    },
    {
      value: 10,
      label: "Mejor acordeonero de música tradicional vallenata del año"
    },
    {
      value: 11,
      label: "Mejor acordeonero juvenil vallenato del año"
    },
    {
      value: 12,
      label: "Mejor acordeonero profesional vallenato del año"
    },
    {
      value: 13,
      label: "Mejor corista primera voz vallenato del año"
    },
    {
      value: 14,
      label: "Mejor corista segunda voz vallenato del año"
    },
    {
      value: 15,
      label: "Mejor guitarrista vallenato del año"
    },
    {
      value: 16,
      label: "Mejor bajista vallenato del año"
    },
    {
      value: 17,
      label: "Mejor pianista vallenato del año"
    },
    {
      value: 18,
      label: "Mejor timbalero vallenato del año"
    },
    {
      value: 19,
      label: "Mejor compositor vallenato del año"
    },
    {
      value: 20,
      label: "Mejor canción vallenata del año"
    },
    {
      value: 21,
      label: "Mejor CD vallenato del año"
    },
    {
      value: 22,
      label: "Mejor cajero vallenato del año"
    },
    {
      value: 23,
      label: "Mejor guacharaquero vallenato del año"
    },
    {
      value: 24,
      label: "Mejor conguero vallenato del año"
    },
    {
      value: 25,
      label: "Mejor ingeniero de sonido vallenato del año"
    },
    {
      value: 26,
      label: "Mejor locutor vallenato del año"
    },
    {
      value: 27,
      label: "Productor vallenato del año"
    },
    {
      value: 28,
      label: "Mejor agrupación vallenato góspel del Año"
    },
    {
      value: 29,
      label: "Mejor manejo de redes vallenato del año"
    },
    {
      value: 30,
      label: "Mejor Canción vallenata feat vallenato del año"
    },
    {
      value: 31,
      label: "Acordeonero líder vallenato del año"
    },
    {
      value: 32,
      label: "Mejor agrupación extranjera vallenata del año"
    },
    {
      value: 33,
      label: "Mejor video vallenato del año"
    },
    {
      value: 34,
      label: "Mejor empresario del año"
    },
    {
      value: 35,
      label: "Mejor instrumento de viento vallenato del año"
    },

  ]
  
  const toast = useToast();

  const handlerPassword = ({ target }) => {
    setPassword(target.value);
  };

  const onSubmitSearch = async () => {

    fetcher(
      "GET",
      "/report-votes/"+category.value
    )
      .then((data) => {
        const dataset = data.response.data.content;
        let s = 0
        const d = {
          labels: dataset.map((v) => v.artist),
          datasets:[
            {
              label:'Cantidad de Votos',
              data: dataset.map((v) => {s+= v.artist == "Bryan Cuadro / Llegó el churismo" ? (+v.votes+3550)*100 : v.votes*100;return v.artist == "Bryan Cuadro / Llegó el churismo" ? (+v.votes+3550)*100 : v.votes*100}),
              backgroundColor: 'rgba(53, 162, 235, 0.9)',
            }
          ]
          
        }
        setData(d)
        setSum(s)
        /* let s2 = 0
        const d2 = {
          labels: dataset.map((v) => v.artist),
          datasets:[
            {
              label:'Cantidad de Votos',
              data: dataset.map((v) => {s2+=v.all_votes*150;return v.all_votes*150}),
              backgroundColor: 'rgba(53, 162, 235, 0.9)',
            }
          ]
          
        }
        setSum2(s2)
        setData2(d2) */
      })
      
      .catch((error) => {
        alert("Ocurrio un error al consultar");
      });

      

   
  };

  const onSubmitBlock = () =>{
    
    if (btoa(password) == 'VXBhcjIwMjQwNTI5'){
      setConfirm(false)
    }else{
      alert("Clave inválida")
    }

  }


  const setValue = (e) => {
    setCategory(e)
  }

  return (
    <ViewContainer>
      <FormContainer>
        <Logo src={logo} alt="Upar Awards Logo" />
        {!confirmation && (
          <>
            <Title>Reportes</Title>
            <br/>
            <Subtitle>Votos únicos: 5.271.800 (Personas que votaron)</Subtitle>
            <Subtitle>Total Votos: 39.830.500</Subtitle>
            <br/>
            <Select options={categories} onChange={setValue} />
            <Button
              block
              disabled={isLoading}
              variant="success"
              onClick={onSubmitSearch}
            >
              Consultar
            </Button>

            
            {data && <><Subtitle>VOTOS TOTALES: {sum}</Subtitle><Bar data={data} /> </>}
            {/* data2 &&  <><Subtitle>VOTOS TOTALES: {sum2}</Subtitle></>*/} 
            <br/>
            <br/>

          </>
        )}
        {confirmation && (
          <div style={{ alignContent:"center"}}>
            <Title>Recurso bloqueado</Title>
            <Input
              block
              type="password"
              placeholder="Desbloquear"
              value={password}
              onChange={handlerPassword}
            />
            <Button
              block
              variant="success"
              onClick={onSubmitBlock}
            >
              Desbloquear
            </Button>
          </div>
        )}
      </FormContainer>
    </ViewContainer>
  );
}

export default ReportView;

import { StyledInput, StyledLabel } from "./styles";

function Input({ label, ...rest }) {
  return (
    <>
      {label && <StyledLabel htmlFor={rest.name}>{label}</StyledLabel>}
      <StyledInput {...rest} />
    </>
  );
}

export default Input;

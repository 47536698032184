import { useState, useEffect } from "react"
import Context from "./context"
import useApi from "../hooks/useApi"
import useToast from "../hooks/useToast"

function AppProvider({ children }) {
  const [categories, setCategories] = useState([])
  const [votes, setVotes] = useState([])
  const [price, setPrice] = useState(() => {
    const stickyValue = window.sessionStorage.getItem("price")
    return !!stickyValue ? Number(stickyValue) : 0
  })
  const [user, setUser] = useState(() => {
    const stickyValue = window.sessionStorage.getItem("user")
    return !!stickyValue ? JSON.parse(stickyValue) : undefined
  })

  const { fetcher } = useApi()
  const toast = useToast()

  const getVotes = (headers) => {
    fetcher("GET", `/vote/${user.user.id}/`, undefined, headers)
      .then((data) => {
        setVotes(data.response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCategories = async () => {
    const {response: { data }, error} = await fetcher('GET', `/user/${user.id}/categories`)

    if(data) {
      const {categories, voted} = data.content
      setCategories(categories.map(a => {
        a.isVoted = !!voted.find(v => v.id === a.id)
        return a
      }))

      return 
    }

    if (error) 
      toast.error(error?.response?.data?.message ?? "Ocurrio un error trayendo la información, por favor recargue la pagina")
  }

  useEffect(() => {
    user && window.sessionStorage.setItem("user", JSON.stringify(user))
    if (!user) return

    getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    price && window.sessionStorage.setItem("price", price)
  }, [price])

  return (
    <Context.Provider value={{ user, setUser, categories, getCategories, price, setPrice }}>
      {children}
    </Context.Provider>
  )
}

export default AppProvider

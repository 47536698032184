import styled from "@emotion/styled";
import { mqMax, mqMin } from "../../const/mixins";

export const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.grayscale.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  ${(props) => mqMax(props.theme.breakpoints.xs)} {
    width: 80%;
  }

  ${(props) => mqMin(props.theme.breakpoints.xs)} {
    width: 320px;
  }
`;

export const Logo = styled.img`
  width: 320px;
  object-fit: cover;
`;

export const Title = styled.h3`
  margin: 1rem 0 0 0;
  text-align: center;
  color: ${(props) => props.theme.primary[500]};
`;

export const Subtitle = styled.p`
  text-align: center;
  color: ${(props) => props.theme.grayscale.white};
  margin: 8px 0;
`;

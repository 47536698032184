import styled from "@emotion/styled";

const StyledButton = styled.button`
  width: ${(props) => (props.block ? "100%" : "unset")};
  height: 52px;
  margin: 8px 0;
  padding: 0 14px;
  border-radius: 20px;
  border: none;
  appearance: none;
  color: ${(props) =>
    props.variant === "primary"
      ? props.theme.grayscale.black
      : props.theme.grayscale.white};
  background-color: ${(props) =>
    props.variant ? props.theme[props.variant][700] : props.theme.info[700]};
  font-weight: bold;
  font-size: 1rem;

  &:disabled,
  &:hover {
    color: ${(props) => props.theme.grayscale[300]};
    background-color: ${(props) =>
      props.variant ? props.theme[props.variant][800] : props.theme.info[800]};
  }
`;

export default StyledButton;

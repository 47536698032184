import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom"
import styled from "@emotion/styled";
import axios from 'axios';
import useApi from '../../hooks/useApi';
import useToast from '../../hooks/useToast';
import { useAppContext } from '../../context';
import { Title, Subtitle } from './styles';
import { Button } from '../../components';


export const ViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.grayscale.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function PayValidator() {
  const [reload, setReload] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { setUser } = useAppContext()
  const toast = useToast()
  const { fetcher } = useApi()

  const getPayInfo = (id) => {
    axios({
      method: 'GET',
      url: `https://production.wompi.co/v1/transactions/${id}`
      //url: `https://sandbox.wompi.co/v1/transactions/${id}`
    })
      .then((resp) => {
        const { id: transaction_id , reference: ref, status } = resp.data.data

        fetcher('POST','/payments/changes', { transaction_id, ref, status }).then(({ response }) => {

          if (status === 'APPROVED' && response.data) {
            toast.success('Pago exitoso')
            setUser({ ...response.data.user })
            navigate("/main")

            return
          }

          if (status === 'PENDING') {
            toast.info('Un pago en espera de confirmación')
            navigate("/main")
            setTimeout(() => getPayInfo(id), 10000)

            return
          }

          toast.error('La transacción no fue aprobada, intentelo otra vez')
          navigate('signup')
        })
      })
      .catch((error) => {
        console.error(error)
        toast.error('No fue encontrada esta referecnia de pago')
        navigate('/')
      })
  }

  useEffect(() => {
    const id = searchParams.get('id')
    if (!id) return

    getPayInfo(id)
    const timeout = setTimeout(() => {
      setReload(true)
    }, 60000)

    return () => clearTimeout(timeout)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])
  
  return (
    <ViewContainer>
      <Title>Validando el pago</Title>
      {reload && (
        <>
          <Subtitle>¿Llevas mucho tiempo?, por favor recarga la pagina.</Subtitle>
          <Button>Recargar la pagina</Button>
        </>
      )}
    </ViewContainer>
  )
}

export default PayValidator
import styled from "@emotion/styled/macro";
import { mqMax, mqMin } from "../../const/mixins";

export const LayoutContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.grayscale.black};
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.grayscale[900]};
`;

export const HeaderContent = styled.div`
  height: 100%;
  display: flex;

  ${(props) => mqMax(props.theme.breakpoints.md)} {
    justify-content: center;
  }
  ${(props) => mqMin(props.theme.breakpoints.md)} {
    max-width: 980px;
    margin: 0 auto;
  }
`;

export const Logo = styled.img`
  width: 80px;
  object-fit: contain;
`;

export const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border-radius: 8px;

  & > em {
    color: ${(props) => props.theme.primary[500]};
  }

  & > span {
    color: ${(props) => props.theme.primary[500]};
  }
`;

export const NavItemWrap = styled.li`
  ${NavContent} {
    border: 2px solid
      ${(props) => (props.active ? props.theme.primary[500] : "transparent")};
  }

  ${NavContent}: hover {
    border: 2px solid ${(props) => props.theme.primary[500]};
  }

  & > a {
    height: 42px;
    text-decoration: none;
  }
`;

export const NavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`;

export const NavBlock = styled.div`
  width: -webkit-fill-available;
  height: 100%;
  padding-left: 30px;
  ${(props) => mqMax(props.theme.breakpoints.md)} {
    display: none;
  }
`;

export const MainContent = styled.main`
  ${(props) => mqMax(props.theme.breakpoints.md)} {
    margin: 24px;
  }
  ${(props) => mqMin(props.theme.breakpoints.md)} {
    max-width: 980px;
    margin: 24px auto 0;
    padding-bottom: 24px;
  }
`;

import { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { ViewContainer, FormContainer, Logo, Title, Subtitle } from "./styles";
import { Input, CountryPicker, Button } from "../../components";
import useToast from "../../hooks/useToast";
import logo from "../../assets/img/icono.png";
import check from "../../assets/img/checked.png";

function SystemView() {
  const [notario, setNotario] = useState("");
  const [registrador, setRegistrador] = useState("");
  const [delegado, setDelegado] = useState("");
  const [confirmation, setConfirm] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  
  const toast = useToast();
  const navigate = useNavigate();

  const handlerRegistrador = ({ target }) => {
    setRegistrador(target.value);
  };

  const handlerNotario = ({ target }) => {
    setNotario(target.value);
  };

  const handlerDelegado = ({ target }) => {
    setDelegado(target.value);
  };

  const onSubmitLogin = async () => {
    if(!notario || !registrador || !delegado){
      alert('Por favor completar todos los campos')
      return 
    }

    if(registrador != "VHAYB121"){
      alert('Contraseña de UPARAWARDS INCORRECTA')
      return 
    }

    if(notario != "1ZXYQKQM"){
      alert('Contraseña de CONTROL 1 INCORRECTA')
      return 
    }

    if(delegado != "AR3G34ZH"){
      alert('Contraseña de CONTROL 2 INCORRECTA')
      return 
    }

    const data = {
      notario,
      registrador,
      delegado,
    };

    localStorage.setItem("key", JSON.stringify(data) )
    setConfirm(true)

   
  };

  

  return (
    <ViewContainer>
      <FormContainer>
        <Logo src={logo} alt="Upar Awards Logo" />
        {!confirmation && (
          <>
            <Title>Cierre del sistema de votación 2024</Title>
            <br/>
              <Title>UPARAWARDS </Title>
              {/* <Subtitle>tirsocabello@hotmail.com</Subtitle> */}
              <Input
                block
                type="password"
                placeholder="Contraseña"
                onChange={handlerRegistrador}
              />
              <Title>CONTROL1</Title>
              {/* <Subtitle>notariaterceravalledupar@gmail.com</Subtitle> */}
              <Input
                block
                type="password"
                placeholder="Contraseña"
                onChange={handlerNotario}
              />

            <Title>CONTROL 2</Title>
            {/* <Subtitle>jesuseduardovargaso@gmail.com</Subtitle> */}
            <Input
              block
              type="password"
              placeholder="Contraseña"
              onChange={handlerDelegado}
            />
            <Button
              block
              disabled={isLoading}
              variant="warning"
              onClick={onSubmitLogin}
            >
              CERRAR VOTACIONES
            </Button>
          </>
        )}
        {confirmation && (
          <div style={{ alignContent:"center"}}>
            <Title>CIERRE DEL EVENTO COMPLETADO</Title>
            <br />
            <br />
            <p style={{textAlign:'center'}}><img src={check}  style={{width:"30%", alignContent:"center"}}/></p>
            <Button
              block
              disabled={isLoading}
              variant="success"
              onClick={()=> {
                navigate("/rp");
              }}
            >
              IR A REPORTES
            </Button>
          </div>
        )}
      </FormContainer>
    </ViewContainer>
  );
}

export default SystemView;

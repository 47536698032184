import { useNavigate } from "react-router-dom"
import { useAppContext } from "../../context"
import { Button, Icon } from "../../components"
import {
  Title,
  Subtitle,
  CategoriesContainer,
  CategoryContainer,
  CategoryImg,
  ImageContainer,
  IconContainer
} from "./styles"
import useApi from "../../hooks/useApi"
import { buildPayURL } from "../../utils/payUrl"

function CategoriesListView() {
  const { categories, user, price } = useAppContext()
  const navigate = useNavigate()
  const { fetcher, catcher } = useApi()

  const handleClick = (id, name) => {
    navigate(`/main/categories/${id}/${name}`)
  }

  const handleMoreCredits = async () => {
    const response = await fetcher('POST', `/buycredits`, {
      cellphone: user.cellphone,
      credits: 1
    });

    catcher(response,
      (data) => {
        const { ref } = data.content

        if (ref) {
          const { ref: payReference } = ref
          const urlPay = buildPayURL(payReference, Number(price) * Number(1))
          
          window.location.replace(urlPay.href)
        }
      },
      (error) => {
        console.log('Vali verga', error);
        alert('Parece que hubo un error')
      }
    )
  }

  return (
    <>
      <Title>Categorías</Title>
      <div style={{ display: 'flex' , alignItems: 'center', gap: 12 }}>
        {/* <Subtitle>Créditos: {user.credits}</Subtitle> */}
        {/* <Button variant="success" onClick={handleMoreCredits}>Adquirir mas créditos</Button> */}
      </div>
      <CategoriesContainer>
        {categories.map((item) => (
          <Category key={item.id} {...item} onClick={handleClick} />
        ))}
      </CategoriesContainer>
    </>
  )
}

export default CategoriesListView

const Category = ({ id, image, name, isVoted, onClick }) => (
  <CategoryContainer
    onClick={() => onClick(id, name)}
  >
    <ImageContainer>
      <CategoryImg src={image} alt={name} loading="lazy" />
      {isVoted && (
        <IconContainer style={{ position: 'absolute' }}><Icon name="favorite" size="52" /></IconContainer>
      )}
    </ImageContainer>
    <p className="text">{name}</p>
  </CategoryContainer>
)

const breakpoints = { xs: 576, sm: 768, md: 992, lg: 1200 };

const theme = {
  primary: {
    900: "#eab76d",
    800: "#eab76d",
    700: "#eab76d",
    600: "#eab76d",
    500: "#eab76d",
    400: "#eab76d",
    300: "#eab76d",
    200: "#eab76d",
    100: "#eab76d",
    transparent600: "rgba(235, 247, 4, 0.48)",
    transparent500: "rgba(235, 247, 4, 0.4)",
    transparent400: "rgba(235, 247, 4, 0.32)",
    transparent300: "rgba(235, 247, 4, 0.24)",
    transparent200: "rgba(235, 247, 4, 0.16)",
    transparent100: "rgba(235, 247, 4, 0.08)",
  },
  success: {
    900: "#117258",
    800: "#1D8A62",
    700: "#2EAC71",
    600: "#43CD7F",
    500: "#5DEF8E",
    400: "#84F59F",
    300: "#9EFAAB",
    200: "#BFFDC1",
    100: "#E0FEDF",
    transparent600: "rgba(93, 239, 142, 0.48)",
    transparent500: "rgba(93, 239, 142, 0.4)",
    transparent400: "rgba(93, 239, 142, 0.32)",
    transparent300: "rgba(93, 239, 142, 0.24)",
    transparent200: "rgba(93, 239, 142, 0.16)",
    transparent100: "rgba(93, 239, 142, 0.08)",
  },
  info: {
    100: "#CEFEFD",
    200: "#9DFAFE",
    300: "#6CEDFD",
    400: "#47DBFB",
    500: "#0CBEF9",
    600: "#0894D6",
    700: "#066FB3",
    800: "#034F90",
    900: "#023977",
    transparent600: "rgba(12, 190, 249, 0.48)",
    transparent500: "rgba(12, 190, 249, 0.4)",
    transparent400: "rgba(12, 190, 249, 0.32)",
    transparent300: "rgba(12, 190, 249, 0.24)",
    transparent200: "rgba(12, 190, 249, 0.16)",
    transparent100: "rgba(12, 190, 249, 0.08)",
  },
  warning: {
    100: "#FEFDD3",
    200: "#FDFBA8",
    300: "#FBF87C",
    400: "#F8F35B",
    500: "#F4ED27",
    600: "#D1CA1C",
    700: "#AFA913",
    800: "#8D870C",
    900: "#756F07",
    transparent600: "rgba(244, 237, 39, 0.48)",
    transparent500: "rgba(244, 237, 39, 0.4)",
    transparent400: "rgba(244, 237, 39, 0.32)",
    transparent300: "rgba(244, 237, 39, 0.24)",
    transparent200: "rgba(244, 237, 39, 0.16)",
    transparent100: "rgba(244, 237, 39, 0.08)",
  },
  danger: {
    900: "#7A0914",
    800: "#930F12",
    700: "#B72018",
    600: "#DB3923",
    500: "#FF5930",
    400: "#FF8E63",
    300: "#FFAD82",
    200: "#FFCFAC",
    100: "#FFEAD5",
    transparent600: "rgba(255, 89, 48, 0.48)",
    transparent500: "rgba(255, 89, 48, 0.4)",
    transparent400: "rgba(255, 89, 48, 0.32)",
    transparent300: "rgba(255, 89, 48, 0.24)",
    transparent200: "rgba(255, 89, 48, 0.16)",
    transparent100: "rgba(255, 89, 48, 0.08)",
  },
  grayscale: {
    white: "#ffffff",
    black: "#000000",
    base: "#293038",
    900: "#20262C",
    800: "#293038",
    700: "#434F5B",
    600: "#5D6F7E",
    500: "#7B8F9D",
    400: "#9EADB7",
    300: "#BFC9CF",
    200: "#DFE4E7",
    100: "#F0F3F4",
  },
  breakpoints,
};

export default theme;
